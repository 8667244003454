<template>
  <div>
    <van-cell-group>
      <van-cell
        v-for="(item, index) in list"
        :key="index"
        :title="item.nodeName"
      >
        <template #label>
          <div>
            <div
              :style="{
                overflow: 'hidden',
                transition: 'height 0.5s',
                height: item.show ? 'auto' : '40px',
              }"
            >
              <van-button
                class="m-b-10 m-r-10"
                size="small"
                v-for="(vm, idx) in item.vmInfo"
                :key="idx"
                :type="vm.onlineStatus ? 'primary' : 'danger'"
                @click="toDetail(vm.vmCode)"
              >
                {{ vm.deviceCode }}
              </van-button>
            </div>
            <div
              v-if="item.vmInfo.length > 3"
              class="w-100 m-t-10 f-cc bg-white"
              @click="onSwitch(index)"
            >
              <van-icon :name="'arrow-' + (item.show ? 'up' : 'down')" />
            </div>
          </div>
        </template>
      </van-cell>
    </van-cell-group>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
    }
  },
  created() {
    this.listEmpNodeVm()
  },
  methods: {
    async listEmpNodeVm() {
      const { data } = await this.$api.listEmpNodeVm()
      console.log('listEmpNodeVm===>', data)
      this.list = data.map((v) => ({ show: false, ...v }))
    },
    onSwitch(index) {
      this.list[index].show = !this.list[index].show
    },
    toDetail(vmCode) {
      this.$router.push({ path: '/machineDetail', query: { vmCode } })
    },
  },
}
</script>